/* You can add global styles to this file, and also import other style files */
// @import './assets/themes/mytheme/theme.scss';
@import './assets/themes/mytheme/variables';
@import './assets/icon-font/style.css';

/************************ General styles start ************************/
:root {
  --text-primary: #6f33b5;
  --red-500: #db3e3e;
  --rose-50: #fff1f3;
  --rose-700: #c01048;
  --blue-50: #eff8ff;
  --blue-700: #175cd3;
  --primary-50: #f9f5ff;
  --primary-200: #e9d7fe;
  --primary-500: #9e77ed;
  --primary-700: #6941c6;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-700: #027a48;
  --warning-50: #fffaeb;
  --warning-700: #b54708;
  --gray-1: #333;
  --gray-2: #09090f;
  --gray-5: #6e717c;
  --gray-6: #f2f2f2;
  --gray-9: #e5e9f2;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;
  --green-1: #219653;
  --font-family: 'Satoshi';
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

body,
.p-component,
.p-chips * {
  font-family: var(--font-family) !important;
}

* {
  margin: 0;
  padding: 0;
}

/**
  * !TODO: erase this
 */
pre {
  white-space: pre-wrap;
}

/*======================= Dashboard start =======================*/
.search {
  width: 100%;
  max-width: 28.375rem;
  margin-inline: 1.25rem;

  .p-input-icon-left {
    position: relative;

    [class^='el'] {
      position: absolute;
      top: 5%;
      left: 1rem;
    }

    .p-inputtext {
      height: 44px;
    }
  }
}
/*======================= Dashboard end =======================*/

form {
  label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray-700);
  }

  button:disabled {
    background: var(--primary-200);
    border: 1px solid var(--primary-200);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
}

.password-control {
  border: 1px solid var(--gray-300);
  border-radius: 0.5rem;
  height: 42.6667px;
}

input[type='checkbox'] {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #ffffff;
  /* Gray/300 */
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:checked {
    background: #f9f5ff;
    border: 1px solid $primaryColor;
    // transition: all cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      content: '\2713';
      font-size: 0.75rem;
      color: $primaryColor;
      font-weight: 900;
    }
  }
  &:focus {
    box-shadow: $focusShadow;
  }
}

input[type='number']::-webkit-inner-spin-button {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

::placeholder {
  color: var(--gray-500);
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--gray-50);

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  span {
    color: var(--gray-600);
  }
}
/************************ General styles end ************************/

/************************ Prime overrides start ************************/
.p-disabled,
.p-component:disabled {
  cursor: not-allowed;
}
.p-fluid {
  width: 100%;

  label {
    display: block;
    margin-bottom: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--gray-700);
  }
}

.p-field:not(:last-of-type),
.p-field:only-of-type {
  margin-block-end: 1.25rem;
}

.dark-mode .p-inputswitch.p-component input[type='checkbox'] {
  display: none !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: var(--gray-100);
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #e4677e;
}

.ng-submitted .p-inputtext.ng-invalid,
.ng-submitted .p-inputwrapper.ng-invalid .p-dropdown {
  border-color: #e4677e;
}
// .p-inputswitch .p-inputswitch-slider:before {
//   background: #ffffff;
//   width: 1rem;
//   height: 1rem;
//   left: 0.25rem;
//   margin-top: -0.625rem;
//   border-radius: 50%;
//   transition-duration: 0.2s;
// }
// .p-inputswitch {
//   width: 36px;
//   height: 20px;
// }

/*---------------- Buttons start ----------------*/
.p-button {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.43;
}
.p-button-loading-icon {
  margin-inline-end: 0.5rem;
}
.p-button.p-button-text:enabled:hover {
  color: inherit;
}
.p-button.p-button-secondary {
  background-color: var(--gray-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.p-button.p-button-outlined.p-button-secondary {
  color: var(--gray-700);
  border-color: var(--gray-700);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
/*---------------- Buttons end ----------------*/

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown .p-dropdown-label,
.p-multiselect-label,
.p-inputtext {
  color: var(--gray-700);
  font-size: 0.875rem;
  font-weight: 500;
  font-family: var(--font-family);
  line-height: 1.5;
}
.p-multiselect .p-multiselect-label.p-placeholder,
.p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 0.875rem;
  color: var(--gray-500);
  line-height: 1.5;
  font-family: var(--font-family);
  font-weight: 400;
}
.p-inputtext.ng-dirty.ng-invalid:focus {
  box-shadow: 0 0 0 0.2rem #f18ea078;
}

/*---------------- Table ----------------*/
.p-datatable {
  border: 1px solid var(--gray-200);
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  overflow: hidden;
}

.p-datatable .p-datatable-header {
  background: #fff;
  color: #101828;
  border: none;
  padding: 24px 20px;
  font-weight: 500;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 12px 20px;
  background: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  font-weight: 500;
  font-size: 12px;
  color: var(--gray-500);
  line-height: 1.5;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid #eaecf0;
  color: var(--nav-link);
  padding: 1rem 1.25rem;
}

.p-datatable .p-datatable-tbody > tr:nth-of-type(even) {
  background: transparent;
}

.user-type {
  padding: 2px 8px;
  border-radius: 16px;
}

.p-datatable .p-paginator-bottom {
  border: none;
  padding: 14px 24px;
}

.p-datatable .p-paginator,
.p-paginator {
  justify-content: space-between;
}

.p-datatable .p-paginator .p-paginator-prev,
.p-paginator .p-paginator-prev,
.p-datatable .p-paginator .p-paginator-next,
.p-paginator .p-paginator-next {
  border-radius: 0.5rem;
  border: 1px solid var(--gray-300);
  color: var(--gray-700);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.p-paginator .p-paginator-prev .p-paginator-icon,
.p-datatable .p-paginator .p-paginator-prev .p-paginator-icon,
.p-paginator .p-paginator-next .p-paginator-icon,
.p-datatable .p-paginator .p-paginator-next .p-paginator-icon {
  display: none;
}

.p-paginator .p-paginator-prev::before,
.p-paginator .p-paginator-next::after {
  font-family: var(--font-family);
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-700);
  padding-inline-end: 0.625rem;
}

.p-paginator .p-paginator-prev::before {
  content: '\1f860 ';
  padding-inline-start: 0.625rem;
}

.p-paginator .p-paginator-prev::after {
  content: ' Previous';
  padding-inline-end: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-700);
}

.p-paginator .p-paginator-next::after {
  padding-left: 0.625rem;
  content: ' \1f862';
}

.p-paginator .p-paginator-next::before {
  content: 'Next ';
  padding-inline-start: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-700);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--gray-50);
  border-radius: 8px;
  color: var(--gray-800);
  border-color: #f9fafb;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border: 1px solid #fff;
  border-radius: 8px;
  color: var(--gray-500);
  font-size: 0.875rem;
}
/*---------------- Table end ----------------*/

/*---------------- Dialog start ----------------*/
.p-component-overlay {
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
}
.p-dialog * {
  font-family: var(--font-family);
}
.p-dialog-content::-webkit-scrollbar {
  width: 0;
}
/*---------------- Dialog end ----------------*/

/*---------------- Menu start ----------------*/
.p-menu {
  &.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    margin-block-start: 1rem;
    box-shadow: 4px 4px 16px 5px rgba(27, 25, 36, 0.12);
    border-radius: 1rem;
    padding: 1.25rem;
  }
  .p-menuitem-link,
  .p-submenu-header {
    padding: 0.5625rem 0.75rem;
  }

  .p-menuitem-link {
    &:not(.p-disabled):hover {
      border-radius: 0.5rem;
      background-color: var(--gray-50);
    }

    .p-menuitem-text {
      color: var(--gray-900);
      font-weight: 500;
      line-height: 1.5;
    }
  }
}
/*---------------- Menu end ----------------*/

/*---------------- Calendar start ----------------*/
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: var(--gray-700);
  border: 1px solid var(--gray-700);
  font-size: 0.875rem;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: transparent;
}
.p-datepicker table {
  font-size: 0.875rem;
}
.p-datepicker table td > span {
  font-weight: 500;
}
/*---------------- Calendar end ----------------*/

/*---------------- Toast start ----------------*/
.p-toast .p-toast-message {
  box-shadow: 0px 4px 6px -2px #10182808;
  border: 1px solid var(--gray-100);
}

.p-toast .p-toast-message.p-toast-message-success {
  background: var(--gray-100);
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  color: #121926;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.25rem 0 0 0;
  color: var(--gray-500);
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.67;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: var(--gray-500);
  border-color: transparent;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: #039855;
  font-size: 1rem;
  border: 8px solid var(--success-100);
  border-radius: 50%;
  position: relative;
  // background: radial-gradient(circle at 100%, var(--success-50));
}
/*---------------- Toast end ----------------*/
/************************ Prime overrides end ************************/

/************************ CK Editor start ************************/
.ck.ck-editor {
  overflow: hidden;
  --ck-border-radius: 0.5rem;
  --ck-color-base-border: #d0d5dd;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 10.8125rem;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-size: 0.875rem;
  font-weight: 500;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border-color: var(--text-primary) !important;
}
/************************ CK Editor end ************************/

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

// Viewer
.viewer-ctr p {
  margin: revert;
}

.viewer-ctr ul,
.viewer-ctr menu,
.viewer-ctr li {
  margin: revert;
  margin-inline-start: 0;
}

.viewer-ctr img {
  width: 100%;
  max-width: 100%;
}
